import { camelCase, range } from "lodash";

const arrayfy = (input) => {
  return Array.isArray(input) ? input : [input];
};

export const getErrorMessage = (error) => {
  let errorMessage;
  if (error.response) {
    errorMessage = `Backend responded with status ${error.response.status} due to ${error.response.data.message}`;
  } else if (error.request) {
    errorMessage = "The request was made but no response was received";
  } else {
    errorMessage = `An error has occurred due to ${error.message}`;
  }
  return errorMessage;
};

export const nameTransformer = (name, format) => {
  if (!name) return null;
  const nameArray = name.split(" ");
  const firstName = nameArray.slice(0, -1).join(" ");
  const lastName = nameArray[nameArray.length - 1];
  if (format === "full") {
    return `${firstName} ${lastName}`;
  }
  if (format === "short") {
    return `${firstName.slice(0, 1)}. ${lastName}`;
  }
  if (format === "list") {
    return [firstName, lastName];
  }
};

export const getWinner = (scores) => {
  let winner;
  if (+scores.homeScore > +scores.awayScore) {
    winner = "home";
  } else if (+scores.homeScore < +scores.awayScore) {
    winner = "away";
  } else {
    winner =
      scores.homeTiebreakScore === undefined ||
      +scores.homeTiebreakScore === +scores.awayTiebreakScore
        ? null
        : +scores.homeTiebreakScore > +scores.awayTiebreakScore
          ? "home"
          : "away";
  }
  return winner;
};

export const compareScores = (chartedPeriodScores, apiPeriodScores) => {
  chartedPeriodScores = chartedPeriodScores.filter(
    (period) => !(period.home_score === 0 && period.away_score === 0)
  );
  apiPeriodScores = apiPeriodScores.filter(
    (period) => !(period.home_score === 0 && period.away_score === 0)
  );
  if (
    apiPeriodScores === undefined ||
    chartedPeriodScores.length !== apiPeriodScores.length
  ) {
    return false;
  }
  for (const i in range(chartedPeriodScores.length)) {
    if (
      chartedPeriodScores[i].home_score !== apiPeriodScores[i].home_score ||
      chartedPeriodScores[i].away_score !== apiPeriodScores[i].away_score
    ) {
      return false;
    }
  }
  return true;
};

export const titlelize = (str) => {
  return str
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase())
    .join(" ");
};

export const getTimeAgoString = (dateTime) => {
  // Get the current date/time
  const now = new Date();

  // Calculate the time difference in milliseconds
  const timeDiff = now - dateTime;

  // Convert the time difference to a positive value
  const positiveTimeDiff = Math.abs(timeDiff);

  // Calculate the different time units
  const seconds = Math.floor(positiveTimeDiff / 1000);
  const minutes = Math.floor(seconds / 60);
  const hours = Math.floor(minutes / 60);
  const days = Math.floor(hours / 24);
  const weeks = Math.floor(days / 7);
  const months = Math.floor(days / 30);
  const years = Math.floor(days / 365);

  // Generate the time ago string
  let timeAgo = "";
  if (now > dateTime) {
    if (years > 0) {
      timeAgo = `${years} year${years > 1 ? "s" : ""} ago`;
    } else if (months > 0) {
      timeAgo = `${months} month${months > 1 ? "s" : ""} ago`;
    } else if (weeks > 0) {
      timeAgo = `${weeks} week${weeks > 1 ? "s" : ""} ago`;
    } else if (days > 0) {
      timeAgo = `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
      timeAgo = `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
      timeAgo = `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
      timeAgo = `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
  } else {
    if (years > 0) {
      timeAgo = `Starting in ${years} year${years > 1 ? "s" : ""}`;
    } else if (months > 0) {
      timeAgo = `Starting in ${months} month${months > 1 ? "s" : ""}`;
    } else if (weeks > 0) {
      timeAgo = `Starting in ${weeks} week${weeks > 1 ? "s" : ""}`;
    } else if (days > 0) {
      timeAgo = `Starting in ${days} day${days > 1 ? "s" : ""}`;
    } else if (hours > 0) {
      timeAgo = `Starting in ${hours} hour${hours > 1 ? "s" : ""}`;
    } else if (minutes > 0) {
      timeAgo = `Starting in ${minutes} minute${minutes > 1 ? "s" : ""}`;
    } else {
      timeAgo = `Starting in ${seconds} second${seconds > 1 ? "s" : ""}`;
    }
  }

  return timeAgo;
};

export const formatDate = (date) => {
  return date
    .toLocaleString("en-US", {
      day: "numeric",
      month: "short",
    })
    .split(" ")
    .reverse()
    .join(" ");
};

export const sortMatchesForDraws = (rounds) => {
  const roundsCopy = structuredClone(rounds);

  for (const roundName of Object.keys(roundsCopy)) {
    const roundCopy = roundsCopy[roundName].filter((round) =>
      round.name.toLowerCase().includes("final")
    );
    // an array to store the "next" numbers required for the previous week
    let requiredNexts = [];
    // starting from the final week
    const reversedRoundCopy = roundCopy.reverse();
    for (const [i, week] of reversedRoundCopy.entries()) {
      const matches = [];
      if (i === 0) {
        for (const match of week.matches.sort(
          (a, b) => a.matchNumber - b.matchNumber
        )) {
          matches.push(match);
        }
      } else {
        for (const next of requiredNexts) {
          // need to push in the matches that have this next
          const matchesWithThisNext = [];
          if (next === null) {
            for (const _ of range(2)) matchesWithThisNext.push(null);
          } else {
            for (const match of week.matches) {
              if (match.next === next) {
                matchesWithThisNext.push(match);
              }
            }
            for (const _ of range(2 - matchesWithThisNext.length)) {
              matchesWithThisNext.push(null);
            }
          }
          // matchesWithThisNext is guaranteed to have length of two now, push both matches into matches variable
          for (const match of matchesWithThisNext) {
            matches.push(match);
          }
        }
        // if there are still leftover matches (those matches that have non finalized next yet)
        if (matches.length !== week.matches.length) {
          console.log(week.name);
          // fill them into matches according to their nexts
          const leftoverMatches = week.matches
            .filter(
              (match) => !matches.map((m) => m && m.id).includes(match.id)
            )
            .sort((a, b) => a.next - b.next);
          console.log(week.matches);
          for (const [j, match] of leftoverMatches.entries()) {
            matches.push(match);
            if (j % 2 === 0) reversedRoundCopy[i - 1].matches.push(null);
          }
        }
      }
      week.matches = matches;
      requiredNexts = matches.map((match) =>
        match ? match.matchNumber : null
      );
    }
    roundsCopy[roundName] = roundCopy.reverse();
  }

  return roundsCopy;
};

export const convertRemToPx = (rem) => {
  return rem * parseFloat(getComputedStyle(document.documentElement).fontSize);
};

export const customSortArray = (array, order) => {
  const result = [];
  for (const i of order) {
    if (array.includes(i)) result.push(i);
  }
  return result;
};

export const transformRoundName = (round, shortForm) => {
  if (!round) return round;
  let result = round;
  const replacements = shortForm
    ? {
        "1/64-finals": "R128",
        "1/32-finals": "R64",
        "1/16-finals": "R32",
        "1/8-finals": "R16",
        "Quarter-finals": "QF",
        "Semi-finals": "SF",
        Final: "F",
      }
    : {
        "1/64-finals": "Round of 128",
        "1/32-finals": "Round of 64",
        "1/16-finals": "Round of 32",
        "1/8-finals": "Round of 16",
        "Quarter-finals": "Quarter Finals",
        "Semi-finals": "Semi Finals",
        Final: "Final",
      };
  for (const [ori, replacement] of Object.entries(replacements)) {
    result = result.replace(ori, replacement);
  }
  return result;
};

const getPointByPoint = (pointByPoint) => {
  if (pointByPoint.includes(undefined) || pointByPoint[0].period.length === 0) {
    return;
  }
  const transformedPointByPoint = {};
  for (const [i, set] of pointByPoint[0].period.entries()) {
    // add normal set data
    if (
      !set.set ||
      pointByPoint[0].period.length !== pointByPoint[1].period.length
    ) {
      continue;
    }
    transformedPointByPoint[camelCase(set.name)] = arrayfy(set.set.game).map(
      (period, j) => ({
        homeScore: period.gameScore.split(" - ")[0],
        awayScore: period.gameScore.split(" - ")[1],
        server: period.servedPlayer === "True" ? "home" : "away",
        homeLostServe:
          pointByPoint[0].period[i].set.game[j].lostServe === "True",
        awayLostServe:
          pointByPoint[1].period[i].set.game[j].lostServe === "True",
        winner:
          period.servedPlayer === "True"
            ? pointByPoint[0].period[i].set.game[j].lostServe === "True"
              ? "away"
              : "home"
            : pointByPoint[1].period[i].set.game[j].lostServe === "True"
              ? "home"
              : "away",
        games: arrayfy(period.points.play).map((game, k) => {
          const plays = pointByPoint.map((pbp) =>
            arrayfy(arrayfy(arrayfy(pbp.period)[i].set.game)[j].points.play)
          );
          return {
            homeScore: plays[0][k].score,
            awayScore: plays[1][k].score,
            homeBreakpoint: plays[0][k].breakPoint === "True",
            awayBreakpoint: plays[1][k].breakPoint === "True",
            homeSetPoint: plays[0][k].setPoint === "True",
            awaySetPoint: plays[1][k].setPoint === "True",
            homeMatchPoint: plays[0][k].matchPoint === "True",
            awayMatchPoint: plays[1][k].matchPoint === "True",
          };
        }),
      })
    );

    // add tiebreak set, if any
    if (
      set.tiebreak !== null &&
      set.tiebreak !== undefined &&
      set.tiebreak.game.length > 0
    ) {
      const lastPeriod = transformedPointByPoint[camelCase(set.name)].pop();
      if (lastPeriod) {
        transformedPointByPoint[camelCase(set.name)].push({
          homeScore: lastPeriod.homeScore,
          awayScore: lastPeriod.awayScore,
          homeTiebreakScore:
            pointByPoint[0].period[i].tiebreak.game.at(-1).gameScore,
          awayTiebreakScore:
            pointByPoint[1].period[i].tiebreak.game.at(-1).gameScore,
          winner:
            lastPeriod.homeScore > lastPeriod.awayScore
              ? "home"
              : lastPeriod.awayScore > lastPeriod.homeScore
                ? "away"
                : null,
          games: set.tiebreak.game.map((game, l) => ({
            homeScore: pointByPoint[0].period[i].tiebreak.game[l].gameScore,
            awayScore: pointByPoint[1].period[i].tiebreak.game[l].gameScore,
            server: game.servedPlayer === "True" ? "home" : "away",
            winner:
              game.servedPlayer === "True"
                ? pointByPoint[0].period[i].tiebreak.game[l].lostServe ===
                  "True"
                  ? "away"
                  : "home"
                : pointByPoint[1].period[i].tiebreak.game[l].lostServe ===
                    "True"
                  ? "home"
                  : "away",
            homeLostServe:
              pointByPoint[0].period[i].tiebreak.game[l].lostServe === "True",
            awayLostServe:
              pointByPoint[1].period[i].tiebreak.game[l].lostServe === "True",
            homeMatchPoint:
              pointByPoint[0].period[i].tiebreak.game[l].matchPoint === "True",
            awayMatchPoint:
              pointByPoint[1].period[i].tiebreak.game[l].matchPoint === "True",
          })),
        });
      }
    }
  }
  return transformedPointByPoint;
};

const getGameStats = (gameStats) => {
  if (
    gameStats.includes(undefined) ||
    gameStats[0].length === 0 ||
    gameStats[0].length !== gameStats[1].length
  ) {
    return;
  }
  const transformedGameStats = {};
  for (const [i, set] of gameStats[0].entries()) {
    transformedGameStats[camelCase(set.name)] = arrayfy(set.type).map(
      (type, j) => ({
        name: type.name,
        stat: arrayfy(type.stat).map((stat, k) => {
          return {
            name: stat.name,
            home: {
              won: arrayfy(arrayfy(gameStats[0][i].type)[j].stat)[k].won,
              total: arrayfy(arrayfy(gameStats[0][i].type)[j].stat)[k].total,
              value: arrayfy(arrayfy(gameStats[0][i].type)[j].stat)[k].value,
            },
            away: {
              won: arrayfy(arrayfy(gameStats[1][i].type)[j].stat)[k].won,
              total: arrayfy(arrayfy(gameStats[1][i].type)[j].stat)[k].total,
              value: arrayfy(arrayfy(gameStats[1][i].type)[j].stat)[k].value,
            },
          };
        }),
      })
    );
  }
  return transformedGameStats;
};

export const matchDetailDataChangeHandlerFactory = (queryClient, scoreId) => {
  return (updatedMatchData) => {
    // queryClient.invalidateQueries(["scores", dateIndex - 7]);
    if (updatedMatchData.operationType === "update") {
      // Update the React Query cache with the new data
      queryClient.setQueryData(["scores", scoreId], (matchDetail) => {
        const updatedMatchId = updatedMatchData.id;
        if (updatedMatchId !== scoreId) return matchDetail;

        if (matchDetail.id === updatedMatchId) {
          let bothPlayersPointByPointUpdated = true;
          let bothPlayersGameStatsUpdated = true;

          if ("status" in updatedMatchData) {
            matchDetail.status = updatedMatchData.status;
          }
          if ("chartingBy" in updatedMatchData) {
            matchDetail.chartingBy = updatedMatchData.chartingBy;
          }
          if ("competitors" in updatedMatchData) {
            for (const [
              i,
              competitor,
            ] of updatedMatchData.competitors.entries()) {
              if (!("pointByPoint" in competitor)) {
                bothPlayersPointByPointUpdated = false;
              }
              if (
                !("stats" in competitor) ||
                competitor.stats.period.length === 0
              ) {
                bothPlayersGameStatsUpdated = false;
              }
              for (const field of Object.keys(competitor)) {
                if (["s1", "s2", "s3", "s4", "s5"].includes(field)) {
                  const setIndex = +field[1] - 1;
                  // if got tiebreak score
                  if (competitor[field].includes(".")) {
                    const [score, tiebreakScore] = competitor[field].split(".");
                    matchDetail.competitors[i].periodScores[setIndex].score =
                      score;
                    matchDetail.competitors[i].periodScores[
                      setIndex
                    ].tiebreakScore = tiebreakScore;
                  } else {
                    matchDetail.competitors[i].periodScores[setIndex].score =
                      competitor[field];
                  }
                } else {
                  matchDetail.competitors[i][field] = competitor[field];
                }
              }
            }
            if (bothPlayersPointByPointUpdated) {
              if (updatedMatchData.id === "2427538")
                console.log(updatedMatchData);
              matchDetail.pointByPoint = getPointByPoint(
                updatedMatchData.competitors.map(
                  (player) => player.pointByPoint
                )
              );
            }
            if (bothPlayersGameStatsUpdated) {
              matchDetail.gameStats = getGameStats(
                updatedMatchData.competitors.map((player) =>
                  arrayfy(player.stats.period)
                )
              );
            }
          }
        }
        return matchDetail;
      });
    }
  };
};
